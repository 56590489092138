import {isNullOrUndefined} from "../helper/utils";
import {parse_query_string} from "../helper/url";

const ApiService = require("services/ApiService");
const NotificationService = require("services/NotificationService");

import TranslationService from "services/TranslationService";

Vue.component("custom-form", {

    components: {
        SlotComponent: {
            functional: true,
            render: (createElement, context) => context.props.vnode
        }
    },

    props: {
        template: {
            type: String,
            default: "#vue-custom-form"
        },
        recipient: {
            type: String,
            default: null
        },
        subject: {
            type: String,
            default: null
        },
        textbefore: {
            type: String,
            default: ""
        },
        textafter: {
            type: String,
            default: ""
        },
        locale: {
            type: String,
            default: 'de'
        },
        gcapture: {
            type: [String, Boolean],
            default: false
        }
    },

    data() {
        return {
            messageSend: false,
            waiting: false,
            error: false,
            message: "Platzhalter für Validierung",
            userMail: "",
            fields: []
        };
    },

    computed: {},

    created() {
        this.$options.template = this.template;

        if (this.$slots.items) {
            this.itemCount = this.$slots.items.length;
        }
        this.textbefore = $("<textarea/>").html(this.textbefore).text();
        this.textafter = $("<textarea/>").html(this.textafter).text();
    },

    mounted() {
        this.$nextTick(() => {
            this.initializeForm();
        });
    },

    methods: {
        prefillValues(){

            let queryparams = parse_query_string(window.location.search.substring(1));
            console.log(queryparams);

            Object.entries(queryparams).forEach(([key, value], index) => {
                let field = $(this.$refs.formContainer).find('[name="' + key + '"]');
                if (field.length > 0) {
                    if (field.attr('type') === "radio") {
                        field.each(function (index, element) {
                            console.log(element);
                            if (element.value === value)
                                $(element).prop('checked', true);
                        })
                    } else {
                        field.val(value);
                    }
                }
                $(this.$refs.formContainer).find('[name="' + key + '[]"][value="' + value + '"]').prop('checked', true);
            });

            $(this.$refs.formContainer).find('input').trigger('input').trigger('change');
        },
        initializeForm() {
            const self = this;
            $(this.$refs.formContainer).find('[type="datetime-local"]').datetimepicker({
                locale: this.locale,
                sideBySide: true
            });
            $(this.$refs.formContainer).find('[type="date"]').datetimepicker({
                locale: this.locale,
                format: 'L'
            });
            $(this.$refs.formContainer).find('[type="time"]').datetimepicker({
                locale: this.locale,
                format: 'LT'
            });
            $(this.$refs.formContainer).find('[type="datetime-local"],[type="time"],[type="date"]').attr('type', 'text');
            $(this.$refs.formContainer).find("[type=\"email\"]").on('change', this.validateEmailField);
            $(this.$refs.formContainer).find("[type=\"email\"]").on('keyup', this.validateEmailField);

            this.prefillValues();

            $(this.$refs.formContainer).on("submit", function (event) {
                event.preventDefault();
                var failed = false;

                if ($(event.target).find("[data-is-user-mail]").length > 0) {
// var email_regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i;
                    var email_regex = /(([^<>()[\]\.,;:\\\/\s@\"]+(\.[^<>()\\\/[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()\\\/[\]\.,;:\s@\"]+\.)+[^<>()\\\/[\]\.,;:\s@\"]{2,})/i;
                    let element = $(event.target).find("[data-is-user-mail]").eq(0);
                    self.userMail = element.val();
                    if (self.userMail.length === 0 || !email_regex.test(self.userMail)) {
                        element[0].setCustomValidity("Invalid field.");
                        $(event.target).next('.invalid-feedback').children('emailmsg').show();
                        failed = true;
                    } else {
                        element[0].setCustomValidity("");
                        $(event.target).next('.invalid-feedback').children('emailmsg').hide();
                    }
                }
                else if ($(event.target).find("[required][type=\"email\"]").length > 0) {
                    // var email_regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i;
                    var email_regex = /(([^<>()[\]\.,;:\\\/\s@\"]+(\.[^<>()\\\/[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()\\\/[\]\.,;:\s@\"]+\.)+[^<>()\\\/[\]\.,;:\s@\"]{2,})/i;
                    let element = $(event.target).find("[type=\"email\"]").eq(0);
                    self.userMail = element.val();
                    if (self.userMail.length === 0 || !email_regex.test(self.userMail)) {
                        element[0].setCustomValidity("Invalid field.");
                        $(event.target).next('.invalid-feedback').children('emailmsg').show();
                        failed = true;
                    } else {
                        element[0].setCustomValidity("");
                        $(event.target).next('.invalid-feedback').children('emailmsg').hide();
                    }
                } else {
                    var email_regex = /(([^<>()[\]\.,;:\\\/\s@\"]+(\.[^<>()\\\/[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()\\\/[\]\.,;:\s@\"]+\.)+[^<>()\\\/[\]\.,;:\s@\"]{2,})/i;
                    let element = $(event.target).find("[type=\"email\"]").eq(0);
                    self.userMail = element.val();
                }

                $('.checkbox-group.required').each(function (index, element) {
                    if ($(element).find("[type='checkbox']:checked").length === 0) {
                        $(element).find("[type='checkbox']").attr('required', true);
                        $(element).find("[type='checkbox']").on('change', function () {
                            $(element).find("[type='checkbox']").attr('required', false);
                        });
                        failed = true;
                    } else {
                        $(element).find("[type='checkbox']").attr('required', false);
                    }
                });
                const message = [];
                self.fields = $(event.target).serializeArray();
                for (let field of self.fields) {
                    if (field.name.length > 0) {
                        message.push("<tr><td>" + field.name + "</td><td>" + field.value + "</td></tr>");
                    }
                }
                self.message = "<table style=\"border: 0 none;\">" + message.join("") + "</table>";
                if (failed || event.target.checkValidity() === false) {
                    $(event.target).addClass('was-validated')
                } else {
                    console.log("send form", event.target);
                    self.validate(this.gcapture);
                }
            });
        },
        validateEmailField(event) {
            // var email_regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/i;
            var email_regex = /(([^<>()[\]\.,;:\\\/\s@\"]+(\.[^<>()\\\/[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()\\\/[\]\.,;:\s@\"]+\.)+[^<>()\\\/[\]\.,;:\s@\"]{2,})/i;

            var email = $(event.target).val();
            if (email.length > 0 && !email_regex.test(email)) {
                // $(event.target).addClass('is-invalid');
                event.target.setCustomValidity("Invalid field.");
                $(event.target).next('.invalid-feedback').children('emailmsg').show();
            } else {
                // $(event.target).removeClass('is-invalid');
                event.target.setCustomValidity("");
                $(event.target).next('.invalid-feedback').children('emailmsg').hide();
            }
        },
        validate(useCapture) {
            // ValidationService.validate($("#contact-form"))
            //     .done(() => {
            if (
                $(this.$refs.formContainer).find("#privacypolicy").length === 0 ||
                $(this.$refs.formContainer).find("#privacypolicy").is(":checked")
            ) {
                if (useCapture) {
                    window.grecaptcha.execute();
                } else {
                    this.sendMail();
                }
            } else {
                this.privacyPolicyShowError = true;

                NotificationService.error(
                    TranslationService.translate("Ceres::Template.contactAcceptFormPrivacyPolicy", {hyphen: "&shy;"})
                );
            }
            // })
            // .fail(invalidFields => {
            //     ValidationService.markInvalidFields(invalidFields, "error");
            //
            //     if (this.enableConfirmingPrivacyPolicy && !this.privacyPolicyAccepted) {
            //         this.privacyPolicyShowError = true;
            //
            //         NotificationService.error(
            //             TranslationService.translate("Ceres::Template.contactAcceptFormPrivacyPolicy", { hyphen: "&shy;" })
            //         );
            //     }
            //
            //     const invalidFieldNames = [];
            //
            //     for (const invalidField of invalidFields) {
            //
            //         let invalidFieldName = invalidField.lastElementChild.innerHTML;
            //
            //         invalidFieldName = invalidFieldName.slice(-1) === "*" ? invalidFieldName.slice(0, invalidFieldName.length - 1) : invalidFieldName;
            //         invalidFieldNames.push(invalidFieldName);
            //     }
            //
            //     NotificationService.error(
            //         TranslationService.translate("Ceres::Template.contactCheckFormFields", { fields: invalidFieldNames.join(", ") })
            //     );
            //
            // });
        },

        sendMail(recaptchaToken = null) {
            this.waiting = true;
            var self = this;
            var originalClass = $(self.$refs.formContainer).find('button[type="submit"] > i').attr('class');
            $(self.$refs.formContainer).find('button[type="submit"] > i').attr('class', 'fa fa-circle-o-notch fa-spin');
            const mailObj =
                {
                    subject: this.subject,
                    message: this.message,
                    userMail: this.userMail,
                    recipient: this.recipient,
                    fields: this.fields,
                    textafter: this.textafter,
                    textbefore: this.textbefore,
                    cc: $(this.$refs.formContainer).find("#cc").is(":checked")
                };

            ApiService.post("/rest/customForms/customer/contact/mail", {
                contactData: mailObj,
                template: "CustomForms::Widgets.Components.ContactMail",
                recaptchaToken: recaptchaToken
            }, {supressNotifications: true})
                .done(response => {
                    this.waiting = false;
                    this.messageSend = true;
                    document.dispatchEvent(new CustomEvent("onContactFormSend", {detail: mailObj}));
                })
                .fail(response => {
                    this.waiting = false;

                    if (response.validation_errors) {
                        for (let error of response.validation_errors) {
                            NotificationService.error(
                                error.message
                            );
                        }
                        this._handleValidationErrors(response.validation_errors);
                    } else {
                        NotificationService.error(
                            TranslationService.translate("CustomForms::Template.formSendError")
                        );
                    }
                })
                .always(() => {
                    $(self.$refs.formContainer).find('button[type="submit"] > i').attr('class', originalClass);
                    if (!isNullOrUndefined(window.grecaptcha)) {
                        window.grecaptcha.reset();
                    }
                });
        },

        clearFields() {
            this.$refs.formContainer.reset();
        },
        createMessage() {
            const message = [];
            let fields = $(this.$refs.formContainer).serializeArray();
            for (field of fields) {
                console.log('field', field);
                if (field.name.length > 0) {
                    message.push("<tr><td>" + field.name + "</td><td>" + field.value + "</td></tr>");
                }
            }
            return "<table style=\"border: 0 none;\">" + message.join("") + "</table>";
        }
    }
});
