module.exports = (function($)
{


    var handlerList = [];

    return {
        log             : _log,
        info            : _info,
        warn            : _warn,
        error           : _error,
        success         : _success
    };

    function _log(message, prefix)
    {
        window.CeresNotification.log()
    }

    function _info(message)
    {
        window.CeresNotification.info(message)
    }

    function _warn(message)
    {
        window.CeresNotification.warn(message)
    }

    function _error(message)
    {
        window.CeresNotification.error(message)
    }

    function _success(message)
    {
        window.CeresNotification.success(message)
    }


})(jQuery);
