import { isNullOrUndefined } from "./utils";

export function normalizeUrl(url)
{
    const urlParts = url.split("?");
    const urlParams = urlParts[1];
    let urlPath = urlParts[0];

    if (App.urlTrailingSlash && urlPath.substr(-1, 1) !== "/")
    {
        urlPath += "/";
    }
    else if (!App.urlTrailingSlash && urlPath.substr(-1, 1) === "/")
    {
        urlPath = url.substr(0, url.length - 1);
    }

    let targetUrl = urlPath;

    if (!isNullOrUndefined(urlParams) && urlParams.length > 0)
    {
        targetUrl += "?" + urlParams;
    }

    return targetUrl;
}

export function pathnameEquals(pathname)
{
    return window.location.pathname === pathname ||
        window.location.pathname === pathname + "/" ||
        window.location.pathname + "/" === pathname;
}

export function parse_query_string(query) {
    var vars = query.split("&");
    var query_string = {};
    for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split("=");
        var key = decodeURIComponent(pair[0]);
        var value = decodeURIComponent(pair[1]);
        // If first entry with this name
        if (typeof query_string[key] === "undefined") {
            query_string[key] = decodeURIComponent(value);
            // If second entry with this name
        } else if (typeof query_string[key] === "string") {
            var arr = [query_string[key], decodeURIComponent(value)];
            query_string[key] = arr;
            // If third or later entry with this name
        } else {
            query_string[key].push(decodeURIComponent(value));
        }
    }
    return query_string;
}
